"use client";

import Hearo from "@/components/Hearo";
import App from "@/components/Faltar";
import BranchDisplayer from "@/components/branchDisplayer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useCallback, Suspense } from "react";
import { appendProducts, resetProducts } from "@/redux/slices/productSlice";
import {
  fetchAllProducts,
  fetchCategories,
  fetchProductsByCategory,
} from "@/services/homeServices";
import { setFavoritesFromProducts } from "@/redux/slices/favoritesSlice";
import { setOffers } from "@/redux/slices/offersSlice";
import {
  fetchHeadOffers,
  fetchOffersAsCategory,
} from "@/services/offersServices";
import { useSearchParams } from "next/navigation";
import { fetchFeedbacksQuistions } from "@/services/feedbackService";
import { setFeedbacks } from "@/redux/slices/feedbacksSlice";
import { fetchAccountPages } from "@/services/accountServices";
import { setAccountPages } from "@/redux/slices/accountSlice";

export default function Home() {
  const dispatch = useDispatch();
  const selectedBranch = useSelector((state) => state.branch.selectedBranch);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const { products } = useSelector((state) => state.product || []);
  const { totalProducts } = useSelector((state) => state.product || 0);
  const { currentPage } = useSelector((state) => state.product || 1);
  const { lastPage } = useSelector((state) => state.product || false);
  const { isAuth } = useSelector((state) => state.auth || false);
  const { maintenanceMode } = useSelector(
    (state) => state.accountPages || false
  );
  const searchParams = useSearchParams();
  const searchQuery = searchParams.get("search");

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const pageInitialized = useRef(false);

  const fetchProducts = useCallback(
    (categoryId = null, pageNum = 1, query = "") => {
      const fetchFunction = categoryId
        ? fetchProductsByCategory
        : fetchAllProducts;
      fetchFunction(categoryId, pageNum, query)
        .then((data) => {
          dispatch(
            appendProducts({
              products: data.products,
              currentPage: data.currentPage,
              lastPage: data.lastPage,
              totalProducts: data.totalProducts,
            })
          );
          dispatch(setFavoritesFromProducts(data.favoriteProductIds));
        })
        .catch((error) =>
          console.error(
            `Error fetching products${
              categoryId ? ` for category ${categoryId}` : ""
            }:`,
            error
          )
        );
    },
    [dispatch]
  );

  const handleCategories = (categoryId) => {
    dispatch(resetProducts());
    if (categoryId === -1) {
      setSubCategories([]);
      fetchOffersAsCategory().then((data) => {
        dispatch(
          appendProducts({
            products: data.products,
            currentPage: data.currentPage,
            lastPage: data.lastPage,
            totalProducts: data.totalProducts,
          })
        );
        dispatch(setFavoritesFromProducts(data.favoriteProductIds));
      });
      return;
    }
    const selectedCategory = categories.find((cat) => cat.id === categoryId);
    if (selectedCategory) {
      setSubCategories(selectedCategory.childern || []);
    }
    fetchProducts(categoryId, 1);
  };

  const handleResetFilter = () => {
    setSubCategories([]);
    dispatch(resetProducts());
    fetchProducts(null, 1);
  };

  const handleShowMore = () => {
    if (!lastPage && !isLoading) {
      const categoryId = subCategories.length > 0 ? subCategories[0].id : null;
      fetchProducts(categoryId, currentPage + 1);
    }
  };

  useEffect(() => {
    fetchHeadOffers(selectedBranch?.id)
      .then((data) => dispatch(setOffers(data)))
      .catch((error) => console.error("Error fetching offers:", error));
    fetchCategories()
      .then(setCategories)
      .catch((error) => console.error("Error fetching categories:", error));
    if (isAuth) {
      fetchFeedbacksQuistions()
        .then((data) => dispatch(setFeedbacks(data)))
        .catch((error) => console.error("Error fetching feedbacks:", error));
    }
    fetchAccountPages()
      .then((data) => dispatch(setAccountPages(data)))
      .catch((error) => console.error("Error fetching account pages:", error));

    if (selectedBranch && products?.length === 0 && !pageInitialized.current) {
      fetchProducts();
      pageInitialized.current = true;
    }
  }, [fetchProducts, selectedBranch, products, dispatch, isAuth]);

  useEffect(() => {
    dispatch(resetProducts());
    if (searchQuery) {
      fetchProducts(null, 1, searchQuery);
    }
  }, [searchQuery, fetchProducts, dispatch]);

  return (
    <Suspense fallback={<div></div>}>
      <main className="px-[4%] mb-24">
        <BranchDisplayer onBranchConfirm={fetchProducts} />
        {!maintenanceMode ? (
          <>
            <Hearo />
            <App
              products={products}
              categories={categories}
              subCategories={subCategories}
              totalProducts={totalProducts}
              isLoading={isLoading}
              onSelectCategory={handleCategories}
              onSelectSubCategory={handleCategories}
              onResetFilter={handleResetFilter}
              selectedBranch={selectedBranch}
            />
          </>
        ) : (
          <div className="flex flex-col items-center justify-center h-[50vh] overflow-hidden scrollbar-hide text-center">
            <h1>{maintenanceMode.message}</h1>
            <h1>{maintenanceMode.reason}</h1>
          </div>
        )}
      </main>

      {!lastPage && !isLoading && products?.length > 0 && (
        <button
          onClick={handleShowMore}
          className="bg-primary text-white px-5 py-3 rounded-full mt-3 mb-9 w-full lg:w-[10%] lg:mx-[45%] text-nowrap"
        >
          {t("showMore")}
        </button>
      )}
    </Suspense>
  );
}
