export const handleScroll = (e, container) => {
  e.preventDefault();

  const SCROLL_SPEED = 8;

  const { scrollLeft, scrollWidth, clientWidth } = container;
  const isRTL = getComputedStyle(container).direction === "rtl";

  const getScrollLeft = () => (isRTL ? -scrollLeft : scrollLeft);

  const setScrollLeft = (value) => {
    container.scrollLeft = isRTL ? -value : value;
  };

  const currentScrollLeft = getScrollLeft();
  const isAtEnd = currentScrollLeft + clientWidth >= scrollWidth - 1;
  const isAtStart = currentScrollLeft <= 0;

  const smoothScroll = (scrollTarget, isHorizontal = true) => {
    const startPosition = isHorizontal ? getScrollLeft() : window.scrollY;
    const distance = scrollTarget - startPosition;
    const duration = 300;
    let startTime = null;

    const animationStep = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeInOut =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      const newPosition = startPosition + distance * easeInOut;

      if (isHorizontal) {
        setScrollLeft(newPosition);
      } else {
        window.scrollTo(0, newPosition);
      }

      if (progress < 1) {
        requestAnimationFrame(animationStep);
      }
    };

    requestAnimationFrame(animationStep);
  };

  if (e.deltaY !== 0) {
    const scaledDelta = e.deltaY * SCROLL_SPEED;
    if (isAtEnd && scaledDelta > 0) {
      smoothScroll(window.scrollY + scaledDelta, false);
    } else if (isAtStart && scaledDelta < 0) {
      smoothScroll(window.scrollY + scaledDelta, false);
    } else {
      smoothScroll(currentScrollLeft + scaledDelta, true);
    }
  }
};

export const handleDragScroll = (container) => {
  let isDragging = false;
  let startX;
  let scrollLeft;

  const onMouseDown = (e) => {
    isDragging = true;
    startX = e.pageX - container.offsetLeft;
    scrollLeft = container.scrollLeft;
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - container.offsetLeft;
    const walk = (x - startX) * 2;
    container.scrollLeft = scrollLeft - walk;
  };

  const onMouseUpOrLeave = () => {
    isDragging = false;
  };

  container.addEventListener("mousedown", onMouseDown);
  container.addEventListener("mousemove", onMouseMove);
  container.addEventListener("mouseup", onMouseUpOrLeave);
  container.addEventListener("mouseleave", onMouseUpOrLeave);

  return () => {
    container.removeEventListener("mousedown", onMouseDown);
    container.removeEventListener("mousemove", onMouseMove);
    container.removeEventListener("mouseup", onMouseUpOrLeave);
    container.removeEventListener("mouseleave", onMouseUpOrLeave);
  };
};
