import { buildHttpResponse } from "@/network/networkUtils";
import { store } from "@/redux/store";
import { getSecureItem } from "@/Utils/secureStorage";

export const fetchAllProducts = async (_, pageNumber, query) => {
  const token = await getSecureItem("user")?.data?.token;
  const response = await buildHttpResponse({
    method: "GET",
    // endPoint: `product?filter[branch]=22&page=${pageNumber}`,
    endPoint: `product?branch=${
      store.getState().branch.selectedBranch?.id
    }&page=${pageNumber}&filter[name]=${query}`,
    // endPoint: `product?filter[branch]=${
    //   store.getState().branch.selectedBranch?.id
    // }&page=${pageNumber}&filter[name]=${query}`,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });
  const { success, message, result } = response.data;

  if (success) {
    const favoriteProductIds = result.data
      .filter((product) => product.is_favorite)
      .map((product) => product.id);

    return {
      products: result.data,
      currentPage: response.data.current_page,
      lastPage: response.data.current_page === response.data.last_page,
      totalProducts: response.data.total,
      favoriteProductIds,
    };
  } else {
    console.log(message);
    throw new Error(message);
  }
};

export const fetchProductsByCategory = async (categoryId, pageNum, query) => {
  const token = await getSecureItem("user")?.data?.token;
  const response = await buildHttpResponse({
    method: "GET",
    // endPoint: `product?filter[branch]=${
    //   store.getState().branch.selectedBranch?.id
    // }&filter[category]=${categoryId}&page=${pageNum}&filter[name]=${query}`,
    endPoint: `product?branch=${
      store.getState().branch.selectedBranch?.id
    }&filter[category]=${categoryId}&page=${pageNum}&filter[name]=${query}`,
    // endPoint: `product?filter[branch]=22&filter[category]=${categoryId}&page=${pageNum}`,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });

  const { success, message, result } = response.data;

  if (success) {
    const favoriteProductIds = result.data
      .filter((product) => product.is_favorite)
      .map((product) => product.id);

    return {
      products: result.data,
      currentPage: response.data.current_page,
      lastPage: response.data.current_page === response.data.last_page,
      totalProducts: response.data.total,
      favoriteProductIds,
    };
  } else {
    console.log(message);
    throw new Error(message);
  }
};

export const fetchCategories = async () => {
  const response = await buildHttpResponse({
    method: "GET",
    endPoint: `menuCategory`,
  });

  const { success, message, result } = response.data;

  if (success) {
    return result.data;
  } else {
    console.log(message);
    throw new Error(message);
  }
};
