import { buildHttpResponse } from "@/network/networkUtils";
import { store } from "@/redux/store";
import { getSecureItem } from "@/Utils/secureStorage";

export const fetchFeedbacksQuistions = async () => {
  const response = await buildHttpResponse({
    method: "GET",
    endPoint: `questionnaire?branch_id=${
      store.getState().branch.selectedBranch?.id
    }`,
    headers: {
      Authorization: `Bearer ${await getSecureItem("user")?.data?.token}`,
    },
  });

  const { success, message, result } = response.data;

  if (success) {
    return result.data;
  } else {
    console.log(message);
    throw new Error(message);
  }
};

export const submitFeedback = async (data) => {
  const response = await buildHttpResponse({
    method: "POST",
    endPoint: "questionnaire/store",
    body: data,
    headers: {
      Authorization: `Bearer ${await getSecureItem("user")?.data?.token}`,
    },
  });
};
