"use client";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CategoryButtons = ({
  categories,
  onSelectCategory,
  onResetFilter,
  subCategories = false,
  selectedBranch,
  t,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { offers } = useSelector((state) => state.offers || []);

  useEffect(() => {
    setSelectedCategory(null);
  }, [selectedBranch]);

  const handleButtonClick = (categoryId) => {
    if (selectedCategory === categoryId) return;
    setSelectedCategory(categoryId);
    onSelectCategory(categoryId);
  };

  return (
    <div className="flex gap-[8px] md:gap-[6px] mb-4 mt-2 overflow-x-auto scrollbar-hide">
      {/* <div className="flex gap-[8px] md:gap-[6px] mb-4 mt-2"> */}
      {!subCategories && (
        <>
          <button
            className={`rounded-[0.75rem] flex items-center justify-center h-[32px] md:h-[38px] text-[12px] md:text-[0.87rem] font-normal p-[12px] ${
              selectedCategory === null
                ? "bg-primary text-white"
                : "bg-[#FAFAFA] text-black"
            }`}
            onClick={() => {
              if (selectedCategory === null) return;
              setSelectedCategory(null);
              onResetFilter();
            }}
          >
            {t("faltar.all")}
          </button>
          {offers.length > 0 && (
            <button
              className={`rounded-[0.75rem] flex items-center text-nowrap justify-center h-[32px] md:h-[38px] text-[12px] md:text-[0.87rem] font-normal p-[12px] ${
                selectedCategory === -1
                  ? "bg-primary text-white"
                  : "bg-[#FAFAFA] text-black"
              }`}
              onClick={() => handleButtonClick(-1)}
            >
              {t("hearo.offers")}
            </button>
          )}
        </>
      )}
      {categories.map((category) => (
        <button
          key={category.id}
          className={`rounded-[0.75rem] flex items-center text-nowrap justify-center h-[32px] md:h-[38px] text-[12px] md:text-[0.87rem] font-normal p-[12px] ${
            selectedCategory === category.id
              ? "bg-primary text-white"
              : "bg-[#FAFAFA] text-black"
          }`}
          onClick={() => handleButtonClick(category.id)}
        >
          {category.name}
        </button>
      ))}
    </div>
  );
};

export default CategoryButtons;
