"use client";

import { useRef, useEffect, useState } from "react";
import Image from "next/image";
import { handleScroll, handleDragScroll } from "@/Utils/scrollUtils";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOffer } from "@/redux/slices/offersSlice";
import OffersPopup from "./offersPopup";
import { useTranslation } from "@/Utils/lanHook";

function Hero() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { offers } = useSelector((state) => state.offers || []);
  const scrollContainerRef = useRef(null);
  const [offerPopup, setOfferPopup] = useState(false);

  useEffect(() => {
    if (offers.length > 0) {
      const container = scrollContainerRef.current;

      const handleWheelScroll = (e) => handleScroll(e, container);

      container.addEventListener("wheel", handleWheelScroll, {
        passive: false,
      });

      const cleanupDragScroll = handleDragScroll(container);

      return () => {
        container.removeEventListener("wheel", handleWheelScroll);
        cleanupDragScroll();
      };
    }
  }, [dispatch]);

  const popUp = () => setOfferPopup(!offerPopup);

  return (
    offers.length > 0 && (
      <section className="w-full flex flex-col md:flex-row mt-[2rem] items-center justify-between md:justify-start h-[213px] md:h-[208px] bg-[#FAFAFA] gap-[16px]">
        <div className="flex flex-col justify-start w-full md:w-44 md:my-[3rem] items-start">
          <h2 className="text-[24px] md:text-[3rem]">{t("hearo.offers")}</h2>
          <p className="text-[16px] md:text-[1.25rem] text-[#666666]">
            {t("hearo.offersTag")}
          </p>
        </div>
        <div
          ref={scrollContainerRef}
          className="scrollbar-hide w-full flex h-[128px] md:h-full gap-[12px] md:gap-[1.25rem] overflow-x-auto rounded-l-xl bg-[#FAFAFA]"
        >
          {offers?.map((offer) => (
            <Image
              key={offer.id}
              src={offer.image}
              alt={offer.name}
              width={1920}
              height={1080}
              draggable={false}
              className="min-w-full h-full object-fill"
              onClick={() => {
                dispatch(setSelectedOffer(offer));
                popUp();
              }}
            />
          ))}
        </div>
        {offerPopup && <OffersPopup onClose={popUp} />}
      </section>
    )
  );
}

export default Hero;
