"use client";

import { useState } from "react";
import Image from "next/image";

function Imagea({ onClose, srcImage }) {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 rounded-2xl">
      <div className="relative w-[1013px] h-[567px] bg-gray-200 rounded-2xl">
        {imageError ? (
          <div className="flex items-center justify-center w-full h-full bg-gray-200 rounded-2xl">
            <p className="text-lg font-semibold text-gray-500">No preview</p>
          </div>
        ) : (
          <Image
            width={1920}
            height={1080}
            src={srcImage}
            alt="Preview"
            onError={handleImageError}
            className="w-full h-full object-cover rounded-2xl"
          />
        )}

        <div
          onClick={onClose}
          className="absolute right-[-52px] top-0 p-[3px] cursor-pointer rounded-[100px] justify-start items-start gap-2.5 inline-flex"
        >
          <div className="w-[33.33px] h-[33.33px] flex justify-center items-center bg-[#FAFAFA] rounded-full">
            <Image src="/close.svg" alt="close" width={12} height={12} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Imagea;
