import { buildHttpResponse } from "@/network/networkUtils";

export const fetchBranches = async () => {
  const response = await buildHttpResponse({
    method: "GET",
    endPoint: `branch`,
  });

  const { success, message, result } = response.data;

  if (success) {
    return result.data;
  } else {
    throw new Error(message);
  }
};
