"use client";

import CategoryButtons from "./CategoryButtons";
import Cards from "./Cards";
import { useTranslation } from "@/Utils/lanHook";

const App = ({
  products,
  categories,
  subCategories,
  totalProducts,
  isLoading,
  onSelectCategory,
  onResetFilter,
  selectedBranch,
}) => {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-8">
      <span className="font-semibold text-[16px] md:text-[1.25rem] mb-1">
        {t("faltar.foodTag")}{" "}
        <sup className="text-[12px] md:text-[0.81rem]">{totalProducts}</sup>
      </span>
      <CategoryButtons
        t={t}
        categories={categories}
        onSelectCategory={onSelectCategory}
        onResetFilter={onResetFilter}
        selectedBranch={selectedBranch}
      />
      {subCategories.length > 0 && (
        <CategoryButtons
          categories={subCategories}
          subCategories={true}
          onSelectCategory={onSelectCategory}
          onResetFilter={onResetFilter}
        />
      )}
      {products?.length === 0 && isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <p className="text-lg">Loading ...</p>
        </div>
      ) : (
        <>
          <Cards products={products} t={t} />
          {/* {isLoading && products?.length > 0 && (
            <div className="w-full h-full flex justify-center items-center mt-4">
              <p className="text-lg">Loading more products...</p>
            </div>
          )} */}
        </>
      )}
    </div>
  );
};

export default App;
