import { buildHttpResponse } from "@/network/networkUtils";

export const fetchAccountPages = async () => {
  const response = await buildHttpResponse({
    method: "GET",
    endPoint: `pageContent`,
  });

  const { success, message, result } = response.data;

  if (success) {
    return result.data;
  } else {
    console.log(message);
    throw new Error(message);
  }
};

export const fetchAccountSettings = async () => {
  const response = await buildHttpResponse({
    method: "GET",
    endPoint: `siteSetting/show`,
  });

  const { success, message, result } = response.data;
  if (success) {
    return result;
  } else {
    console.log(message);
    throw new Error(message);
  }
};
