import { buildHttpResponse } from "@/network/networkUtils";
import { store } from "@/redux/store";

export const fetchHeadOffers = async (branchId) => {
  const response = await buildHttpResponse({
    method: "GET",
    endPoint: `offer?branch=${branchId}`,
  });

  const { success, message, result } = response.data;

  if (success) {
    return result.data;
  } else {
    console.log(message);
    throw new Error(message);
  }
};

export const fetchOffersAsCategory = async () => {
  const response = await buildHttpResponse({
    method: "GET",
    endPoint: `offer/show-all?branch=${
      store.getState().branch.selectedBranch?.id
    }`,
  });

  const { success, message, result } = response.data;

  if (success) {
    const favoriteProductIds = result.data
      .filter((product) => product.is_favorite)
      .map((product) => product.id);

    return {
      products: result.data,
      currentPage: response.data.current_page,
      lastPage: response.data.current_page === response.data.last_page,
      totalProducts: response.data.total,
      favoriteProductIds,
    };
  } else {
    console.log(message);
    throw new Error(message);
  }
};
