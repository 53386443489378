"use client";

import { useState, useEffect, useRef } from "react";
import Image from "next/image";

import Imagea from "./Image";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches } from "@/services/branchServices";
import { updateBranch } from "@/redux/slices/branchSlice";
import MapDialog from "./mapDialog";
import NearBranchesSideBar from "./nearBranchesSideBar";
import { resetProducts } from "@/redux/slices/productSlice";
import { clearCart } from "@/redux/slices/cartSlice";
import { useSearchParams } from "next/navigation";
import { LocationsSvg } from "@/svgs/locationsSvg";
import { useTranslation } from "@/Utils/lanHook";

const BranchDisplayer = ({ onBranchConfirm }) => {
  const [branches, setBranches] = useState([]);
  const { t } = useTranslation();
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupImage, setIsPopupImage] = useState(false);
  const branch = useSelector((state) => state.branch.selectedBranch);
  const dispatch = useDispatch();
  const searchParams = useSearchParams();

  const popup = () => setIsPopupOpen(!isPopupOpen);
  const openImage = () => setIsPopupImage(true);
  const closeImage = () => setIsPopupImage(false);

  const branchesFetchedRef = useRef(false);

  useEffect(() => {
    if (branchesFetchedRef.current) return;

    const fetchDefaultBranch = async () => {
      const branchesData = await fetchBranches();
      const defaultBranch = branchesData.find((b) => b.default === 1);

      setBranches(branchesData);

      const branchIdParam = searchParams.get("branch_id");

      if (branchIdParam) {
        const branchFromURL = branchesData.find(
          (b) => b.id === parseInt(branchIdParam)
        );
        if (branchFromURL) {
          setSelectedBranch(branchFromURL);
          dispatch(updateBranch(branchFromURL));
        }
      } else {
        const storedBranch = localStorage.getItem("branch");

        if (storedBranch) {
          const parsedBranch = JSON.parse(storedBranch);

          const validBranch = branchesData.find(
            (b) => b.id === parsedBranch.id
          );
          if (validBranch) {
            setSelectedBranch(validBranch);
            dispatch(updateBranch(validBranch));
          } else {
            setSelectedBranch(defaultBranch);
            dispatch(updateBranch(defaultBranch));
          }
        } else {
          setSelectedBranch(defaultBranch);
          dispatch(updateBranch(defaultBranch));
        }
      }
    };
    branchesFetchedRef.current = true;
    fetchDefaultBranch();
  }, [dispatch, searchParams]);

  const handleBranch = (branch) => {
    setSelectedBranch(branch);
  };

  const confirmClick = () => {
    dispatch(updateBranch(selectedBranch));
    dispatch(clearCart());
    popup();

    if (onBranchConfirm) {
      dispatch(resetProducts());
      onBranchConfirm();
    }
  };

  return (
    <div className="w-full h-[381px] md:h-full max-w-[1424px] lg:h-[110px] justify-start items-center gap-8 inline-flex">
      <div className="lg:h-[110px] p-2 bg-[#f9f9f9] w-full rounded-3xl flex flex-col lg:flex-row justify-start items-center gap-2 lg:inline-flex">
        {branch ? (
          <>
            <Image
              width={198}
              height={94}
              onClick={openImage}
              className="md:w-full hidden lg:flex lg:w-[198px] md:h-[183px] lg:h-[94px] rounded-[18px]"
              src={branch.image || "/branchImage.png"}
              alt={branch.title}
            />
            <div className="grow h-full shrink basis-0 flex-col md:flex-row  self-stretch cursor-pointer justify-start items-center md:h-[94px] gap-3 flex">
              <Image
                width={198}
                height={94}
                onClick={openImage}
                className=" w-full h-[183px] md:w-[238px]  lg:hidden md:h-[94px] flex  rounded-[18px]"
                src={branch.image || "/branchImage.png"}
                alt={branch.title}
              />
              {/* <div className="grow shrink h-[107px]  md:w-full basis-0 md:mt-3 lg:mt-0 md:h-[94px] px-4 py-[7px] bg-white rounded-[18px] flex-col justify-center items-start gap-2 "> */}

              <div className="grow shrink h-[107px] w-full basis-0 md:mt-3 md:h-[110px]  lg:mt-0 lg:h-[94px]  px-4 py-[17px] md:py-[7px]  bg-white rounded-[18px] flex flex-col justify-center items-start gap-2 ">
                <div className="justify-center items-start gap-[7px] inline-flex">
                  <div className="text-primary text-lg md:text-xl font-semibold leading-normal">
                    {branch.title} ({branch.id}){" "}
                    <sup className="text-[#34c759] text-sm md:text-base font-normal leading-snug">
                      {t("branch.open")} : {branch.open_at}
                    </sup>
                  </div>
                </div>
                <div className="text-[#adaaaa] text-sm md:text-base font-normal leading-tight">
                  {branch.features}
                </div>
                <div className="h-[13px] justify-center items-center gap-1.5 inline-flex">
                  <div className="w-4 h-4 justify-center items-center flex">
                    <div className="w-4 h-4 relative">
                      <Image
                        width={16}
                        height={16}
                        src="/locationn.svg"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="text-[#adaaaa] text-sm md:text-base font-normal leading-tight flex gap-1 justify-start items-end">
                    {branch.address}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>{t("branch.noBranch")}</p>
        )}

        <div className="self-stretch flex justify-start items-center gap-2 md:mt-4 lg:mt-0">
          <div
            onClick={popup}
            className="w-1/2 lg:w-[132px] h-[51px] lg:h-full cursor-pointer self-stretch px-2.5 pt-4 lg:pt-5  bg-primary rounded-[18px] flex-col justify-start items-center gap-4 inline-flex"
          >
            <div className="w-6 h-6 justify-center hidden lg:flex items-center ">
              <div className="w-6 h-6 relative">
                <div className="w-[18px] h-[17.98px] left-[3px] top-[3.01px] absolute">
                  <Image
                    width={24}
                    height={24}
                    src="/arrow-2.svg"
                    alt="image"
                  />
                </div>
              </div>
            </div>
            <div className="text-white lg:text-base font-normal leading-tight">
              {t("branch.changeBranch")}
            </div>
          </div>
          <div
            onClick={popup}
            className="w-1/2 lg:w-[132px] h-[51px] lg:h-full cursor-pointer self-stretch px-2.5 pt-4 lg:pt-5 bg-primary/10 rounded-[18px] flex-col justify-start items-center gap-4 inline-flex"
          >
            <div className="w-6 h-6 justify-center hidden lg:flex items-center ">
              <div className="w-6 h-6 relative">
                <LocationsSvg />
              </div>
            </div>
            <div className="text-primary text-nowrap font-normal leading-tight">
              {t("branch.locationBranch")}
            </div>
          </div>

          {isPopupOpen && (
            <MapDialog
              onSelectedMarker={handleBranch}
              data={branches}
              closeDialog={popup}
              selectedBranch={selectedBranch}
              sidebar={
                <NearBranchesSideBar
                  branches={branches}
                  handleBranchSelect={handleBranch}
                  selectedBranch={selectedBranch}
                  confirmClick={confirmClick}
                />
              }
            />
          )}
          {isPopupImage && <Imagea onClose={closeImage} />}
        </div>
      </div>
    </div>
  );
};

export default BranchDisplayer;
