import { Clock, Location } from "iconsax-react";
import React from "react";

export default function NearBranchesSideBar({
  branches = [],
  handleBranchSelect = () => {},
  selectedBranch = "",
  confirmClick = () => {},
}) {
  return (
    <>
      <div className="hidden md:block">
        <div className="w-full h-[508px]  flex-col">
          <div className="h-10 w-full bg-white justify-between items-center inline-flex mb-2">
            <div className="flex-col justify-center items-start gap-1 inline-flex">
              <div className="text-black text-[16px] font-semibold leading-tight">
                Select the branch
              </div>
              <div className="text-neutral-400 text-xs font-medium leading-[14.44px]">
                Description
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2 w-full h-[460px] rounded-xl  overflow-y-auto scrollbar-hide">
            {branches?.map((branch, index) => (
              <React.Fragment key={branch.id}>
                <label
                  className={`flex flex-col items-start space-x-2 p-2 rounded-xl cursor-pointer transition-colors ${
                    selectedBranch === branch
                      ? "bg-primary text-white"
                      : "bg-white hover:bg-slate-100 "
                  }`}
                  onClick={() => {
                    handleBranchSelect(branch);
                  }}
                >
                  <div className="flex items-center justify-between w-full">
                    <span
                      className={`text-black text-[16px] font-semibold leading-[22px] ${
                        selectedBranch === branch ? "text-white " : "text-black"
                      }`}
                    >
                      {branch.title}
                    </span>
                    <input
                      type="radio"
                      name="branch"
                      value={branch.lat_lng}
                      checked={selectedBranch === branch}
                      onChange={() => {
                        handleBranchSelect(branch.lat_lng);
                      }}
                      className={`mr-2 rounded-full ${
                        selectedBranch === branch ? "opacity-100" : "opacity-0"
                      } transition-opacity`}
                      style={{
                        width: "10px",
                        height: "10px",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-1 mt-2">
                    <Clock
                      className={`w-[14px] h-[14px] md:w-[16px] md:h-[16px] ${
                        selectedBranch === branch
                          ? "text-white"
                          : "text-[#797676]"
                      }`}
                    />

                    <label className="text-[#34C759] text-[14px] font-normal leading-[22px]">
                      Open at {branch.open_at}
                    </label>
                  </div>
                  <div className="flex items-center gap-1 ">
                    <Clock
                      className={`w-[14px] h-[14px] md:w-[16px] md:h-[16px] opacity-0 ${
                        selectedBranch === branch
                          ? "text-white"
                          : "text-[#797676]"
                      }`}
                    />

                    <label className="text-[#34C759] text-[14px] font-normal leading-[22px]">
                      Close at {branch.close_at}
                    </label>
                  </div>
                  <div className="flex items-start my-1">
                    <Location
                      className={`w-[30px] h-[30px] pb-3 ${
                        selectedBranch === branch
                          ? "text-white"
                          : "text-[#797676]"
                      }`}
                    />

                    <label
                      className={` text-[14px] font-normal leading-[21px] ${
                        selectedBranch === branch
                          ? "text-white"
                          : "text-[#797676]"
                      }`}
                    >
                      {branch.address}{" "}
                    </label>
                  </div>
                </label>
                {index < branches.length - 1 && (
                  <hr className="my-2 border-[#F4F0EF]" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <button
          onClick={confirmClick}
          className="mt-2 bg-primary w-full h-[43px] text-white px-4 py-2 rounded text-[16px] font-semibold leading-[19.25px]"
        >
          Confirm
        </button>
      </div>
      <div className="md:hidden">
        <div className="flex w-full rounded-xl scrollbar-hide">
          {branches?.map((branch, index) => (
            <React.Fragment key={branch.id}>
              <label
                className={`rounded-xl cursor-pointer transition-colors mx-2 px-4 py-1 ${
                  selectedBranch === branch
                    ? "bg-primary text-white"
                    : "bg-white hover:bg-slate-100 "
                }`}
                onClick={() => {
                  handleBranchSelect(branch);
                }}
              >
                <div className="text-center">
                  <span
                    className={`text-black text-[16px] font-semibold leading-[22px] ${
                      selectedBranch === branch ? "text-white " : "text-black"
                    }`}
                  >
                    {branch.title}
                  </span>
                  <input
                    type="radio"
                    name="branch"
                    value={branch.lat_lng}
                    checked={selectedBranch === branch}
                    onChange={() => {
                      handleBranchSelect(branch.lat_lng);
                    }}
                    className={`mr-2 rounded-full ${
                      selectedBranch === branch ? "opacity-100" : "opacity-0"
                    } transition-opacity`}
                    style={{
                      width: "10px",
                      height: "10px",
                    }}
                  />
                </div>
                <div className="flex">
                  <div className="flex items-center gap-1 mt-2">
                    <Clock
                      className={`${
                        selectedBranch === branch
                          ? "text-white"
                          : "text-[#797676]"
                      }`}
                    />

                    <label className="text-[#34C759] text-[14px] font-normal leading-[22px]">
                      Open at {branch.open_at}
                    </label>
                  </div>
                  <div className="flex items-center gap-1 ">
                    <Clock
                      className={`opacity-0 ${
                        selectedBranch === branch
                          ? "text-white"
                          : "text-[#797676]"
                      }`}
                    />

                    <label className="text-[#34C759] text-[14px] font-normal leading-[22px]">
                      Close at {branch.close_at}
                    </label>
                  </div>
                </div>
                <div className="flex items-start my-1">
                  <Location
                    className={`w-[30px] h-[30px] pb-3 ${
                      selectedBranch === branch
                        ? "text-white"
                        : "text-[#797676]"
                    }`}
                  />

                  <label
                    className={` text-[14px] font-normal leading-[21px] ${
                      selectedBranch === branch
                        ? "text-white"
                        : "text-[#797676]"
                    }`}
                  >
                    {branch.address}{" "}
                  </label>
                </div>
              </label>
            </React.Fragment>
          ))}
        </div>
        <button
          onClick={confirmClick}
          className="fixed bottom-[18%] left-[12%] bg-primary w-[75%] h-[43px] text-white px-4 py-2 rounded text-[16px] font-semibold leading-[19.25px]"
        >
          Confirm
        </button>
      </div>
    </>
  );
}
